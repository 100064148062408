<template>
  <v-container fluid>
    <div class="app">
      <div class="user-wrapper">
        <div
          class="
            md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-50
          "
        >
          <div class="md-card md-theme-default">
            <div class="search_customer">
              <v-row>
                <v-col
                  style="
                    text-align: right;
                    right: 27px;
                    position: relative;
                    top: 12px;
                  "
                >
                  <v-btn small @click="goToCompany" right top>
                    <v-icon small>mdi-backburger</v-icon>Back
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-spacer></v-spacer>
                  <div class="header_title ml-4">Sales Orders</div>
                </v-col>
                <v-spacer></v-spacer>

                <v-col md="2">
                  <div style="float: right">
                    <v-btn
                      v-if="
                        checkWritePermission($modules.corporate.events.slug)
                      "
                      @click="addEventOrder"
                      class="mr-6 teal-color"
                      dark
                      height="45"
                    >
                      Sales Order
                      <v-icon right dark>mdi-plus-circle</v-icon>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
              <v-row v-if="false">
                <v-col md="2">
                  <v-autocomplete
                    v-model="searchParams.time_intervel"
                    solo
                    filled
                    class="ml-4"
                    label="Time Period"
                    @change="timeperiodChangeFxn"
                    item-value="name"
                    item-text="title"
                    :items="timeDuration"
                  ></v-autocomplete>
                </v-col>

                <v-col md="3" v-if="flag">
                  <v-layout row wrap>
                    <v-flex xs5>
                      <v-menu
                        v-model="menu1"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            solo
                            filled
                            class="text1"
                            v-model="date1Formatted"
                            readonly
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="searchParams.start_date"
                          @change="$forceUpdate()"
                          @input="menu1 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-flex>
                    <v-flex xs5>
                      <v-menu
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            class="text2"
                            v-model="date2Formatted"
                            solo
                            filled
                            readonly
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="searchParams.end_date"
                          @change="$forceUpdate()"
                          @input="menu2 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-flex>
                    <v-flex xs1 class="ml-1">
                      <v-btn
                        small
                        color="#00b0af"
                        dark
                        height="32px"
                        @click="loadEventOrders"
                        >Go</v-btn
                      >
                    </v-flex>
                  </v-layout>
                </v-col>
                <v-spacer></v-spacer>
                <v-col md="2" class="mr-6">
                  <div style="width: 120px; float: right">
                    <v-select
                      solo
                      filled
                      v-model="perPage"
                      label="Per Page"
                      :items="[10, 15, 25, 50]"
                      @change="loadEventOrders"
                    ></v-select>
                  </div>
                </v-col>
              </v-row>
            </div>

            <div class="md-card-content">
              <div>
                <div
                  class="md-content md-table md-theme-default table_borders"
                  table-header-color="orange"
                  value
                >
                  <div
                    class="
                      md-content md-table-content md-scrollbar md-theme-default
                    "
                  >
                    <table class="logTable">
                      <thead class="md-card-header">
                        <tr>
                          <th class="md-table-head">
                            <div
                              class="
                                md-table-head-container md-ripple md-disabled
                              "
                            >
                              <div
                                class="md-table-head-label cursor-pointer"
                                @click="sortColumn('created_at')"
                              >
                                Timestamp
                                <v-icon class="ml-2" color="#fff" small>
                                  mdi-sort{{
                                    orderBy == "created_at"
                                      ? "-" +
                                        (orderDir == "ASC"
                                          ? "ascending"
                                          : "descending")
                                      : ""
                                  }}
                                </v-icon>
                              </div>
                              <div class="search_column">
                                <v-menu
                                  ref="datemenu"
                                  v-model="datemenu"
                                  :close-on-content-click="false"
                                  :return-value.sync="searchParams.timestamp"
                                  transition="scale-transition"
                                  offset-y
                                  min-width="290px"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      v-model="searchParams.timestamp"
                                      label="Purachase Date"
                                      readonly
                                      solo
                                      dense
                                      v-bind="attrs"
                                      v-on="on"
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="searchParams.timestamp"
                                    no-title
                                    scrollable
                                  >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                      text
                                      color="primary"
                                      @click="
                                        () => {
                                          searchParams.timestamp = null;
                                          datemenu = false;
                                          $refs.datemenu.save(
                                            searchParams.timestamp
                                          );
                                          loadEventOrders();
                                        }
                                      "
                                      >Clear</v-btn
                                    >
                                    <v-btn
                                      text
                                      color="primary"
                                      @click="datemenu = false"
                                      >Cancel</v-btn
                                    >
                                    <v-btn
                                      text
                                      color="primary"
                                      @click="
                                        () => {
                                          $refs.datemenu.save(
                                            searchParams.timestamp
                                          );
                                          loadEventOrders();
                                        }
                                      "
                                      >OK</v-btn
                                    >
                                  </v-date-picker>
                                </v-menu>
                              </div>
                            </div>
                          </th>
                          <th class="md-table-head">
                            <div
                              class="
                                md-table-head-container md-ripple md-disabled
                              "
                            >
                              <div class="md-table-head-label">Order ID</div>
                              <div class="search_column">
                                <v-text-field
                                  solo
                                  filled
                                  label="Order ID"
                                  clearable
                                  v-model="searchParams.name"
                                  @change="loadEventOrders"
                                  @keyup="loadEventOrders"
                                ></v-text-field>
                              </div>
                            </div>
                          </th>
                          <th class="md-table-head">
                            <div
                              class="
                                md-table-head-container md-ripple md-disabled
                              "
                            >
                              <div class="md-table-head-label cursor-pointer">
                                Start Date
                              </div>
                              <div class="search_column">
                                <date-menu
                                  v-model="searchParams.from_date"
                                  @change="loadEventOrders"
                                >
                                </date-menu>
                              </div>
                            </div>
                          </th>
                          <th class="md-table-head">
                            <div
                              class="
                                md-table-head-container md-ripple md-disabled
                              "
                            >
                              <div class="md-table-head-label cursor-pointer">
                                End Date
                              </div>
                              <div class="search_column">
                                <date-menu
                                  v-model="searchParams.to_date"
                                  @change="loadEventOrders"
                                >
                                </date-menu>
                              </div>
                            </div>
                          </th>

                          <th class="md-table-head">
                            <div
                              class="
                                md-table-head-container md-ripple md-disabled
                              "
                            >
                              <div class="md-table-head-label">Status</div>
                              <div class="search_column">
                                <v-select
                                  @change="loadEventOrders"
                                  solo
                                  filled
                                  label="Status"
                                  v-model="searchParams.status_id"
                                  item-value="status"
                                  item-text="name"
                                  :items="[
                                    { status: null, name: 'All' },
                                    { status: 1, name: 'Active' },
                                    { status: 2, name: 'Inactive' },
                                  ]"
                                ></v-select>
                              </div>
                            </div>
                          </th>

                          <th class="md-table-head">
                            <div
                              class="
                                md-table-head-container md-ripple md-disabled
                              "
                            >
                              <div class="md-table-head-label">
                                Advance Payment
                              </div>
                              <div class="search_column">
                                <v-menu
                                  ref="priceMenu"
                                  v-model="priceMenu"
                                  :close-on-content-click="false"
                                  :nudge-width="50"
                                  max-width="150"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      label="Amount"
                                      solo
                                      v-bind="attrs"
                                      v-on="on"
                                    ></v-text-field>
                                  </template>
                                  <v-card width="150">
                                    <v-card-text>
                                      <v-text-field
                                        dense
                                        v-model="searchParams.from_amount"
                                        label="Price From"
                                      ></v-text-field>
                                      <v-text-field
                                        dense
                                        v-model="searchParams.to_amount"
                                        label="Price To"
                                      ></v-text-field>
                                    </v-card-text>
                                    <v-card-actions>
                                      <v-spacer></v-spacer>
                                      <v-btn
                                        text
                                        @click="
                                          () => {
                                            searchParams.from_amount = null;
                                            searchParams.to_amount = null;
                                            priceMenu = false;
                                            $refs.priceMenu.save(
                                              searchParams.from_amount,
                                              searchParams.to_amount
                                            );
                                            loadEventOrders();
                                          }
                                        "
                                        >Clear</v-btn
                                      >
                                      <v-btn
                                        color="primary"
                                        @click="
                                          () => {
                                            priceMenu = false;
                                            loadEventOrders();
                                          }
                                        "
                                        >Filter</v-btn
                                      >
                                    </v-card-actions>
                                  </v-card>
                                </v-menu>
                              </div>
                            </div>
                          </th>
                          <th class="md-table-head">
                            <div
                              class="
                                md-table-head-container md-ripple md-disabled
                              "
                            >
                              <div class="md-table-head-label">Bookings</div>
                            </div>
                          </th>
                          <th class="md-table-head">
                            <div
                              class="
                                md-table-head-container md-ripple md-disabled
                              "
                            >
                              <div class="md-table-head-label">Credits</div>
                            </div>
                          </th>
                          <th
                            class="md-table-head"
                            v-if="
                              checkDeletePermission(
                                $modules.corporate.events.slug
                              ) ||
                              checkWritePermission(
                                $modules.corporate.events.slug
                              )
                            "
                          >
                            <div
                              class="
                                md-table-head-container md-ripple md-disabled
                              "
                            >
                              <div class="md-table-head-label">Action</div>
                            </div>
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr
                          class="md-table-row"
                          v-for="data in eventOrderList"
                          :key="data.id"
                        >
                          <td class="md-table-cell">
                            <div class="md-table-cell-container">
                              {{ data.timestamp | timeStamp }}
                            </div>
                          </td>

                          <td class="md-table-cell">
                            <div class="md-table-cell-container">
                              {{ data.sale_seq_no || "NA" }}
                            </div>
                          </td>
                          <td class="md-table-cell">
                            <div class="md-table-cell-container">
                              {{ data.start_date | dateformat }}
                            </div>
                          </td>
                          <td class="md-table-cell">
                            <div class="md-table-cell-container">
                              {{ data.end_date | dateformat }}
                            </div>
                          </td>
                          <td class="md-table-cell">
                            <div class="md-table-cell-container">
                              {{
                                data.status == 1
                                  ? "Active"
                                  : data.status == 12
                                  ? "Completed"
                                  : "Inactive"
                              }}
                            </div>
                          </td>
                          <td class="md-table-cell">
                            <div
                              v-if="data.amount != null"
                              class="md-table-cell-container text-capitalize"
                            >
                              {{ Number(data.current_balance) | toCurrency }}/
                              {{ Number(data.amount) | toCurrency }}
                            </div>
                            <span v-else>NA</span>
                          </td>
                          <td class="md-table-cell">
                            <div class="md-table-cell-container">
                              <v-btn
                                normal
                                small
                                @click="
                                  (pageBookings = 1),
                                    getEventorderBookings(data, 'booking')
                                "
                                >Bookings</v-btn
                              >
                            </div>
                          </td>
                          <td class="md-table-cell">
                            <div class="md-table-cell-container">
                              <v-btn
                                @click="
                                  (pageBookings = 1),
                                    getEventorderBookings(data, 'settle')
                                "
                                normal
                                small
                                >Settle</v-btn
                              >
                            </div>
                          </td>
                          <td
                            class="md-table-cell"
                            v-if="
                              checkDeletePermission(
                                $modules.corporate.events.slug
                              ) ||
                              checkWritePermission(
                                $modules.corporate.events.slug
                              )
                            "
                          >
                            <div
                              class="
                                md-table-cell-container
                                d-flex
                                justify-sm-space-around
                              "
                            >
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    v-if="
                                      checkWritePermission(
                                        $modules.corporate.events.slug
                                      )
                                    "
                                    v-bind="attrs"
                                    v-on="on"
                                    normal
                                    icon
                                    color="success"
                                    @click="populateEditEventOrder(data)"
                                    ><v-icon>mdi-pencil</v-icon></v-btn
                                  >
                                </template>
                                <span>Edit</span>
                              </v-tooltip>
                              <v-tooltip
                                bottom
                                v-if="
                                  checkDeletePermission(
                                    $modules.corporate.events.slug
                                  ) && data.status == 1
                                "
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    v-if="data.order_count == 0"
                                    normal
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                    color="error"
                                    @click="deleteEventOrder(data.sales_id)"
                                    ><v-icon>mdi-delete</v-icon></v-btn
                                  >
                                </template>
                                <span>Delete</span>
                              </v-tooltip>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <v-pagination
                  v-model="page"
                  :length="totalPages"
                ></v-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <v-dialog v-model="addEventOrderDialoge" scrollable width="50%">
      <v-form ref="form" v-model="valid">
        <v-card>
          <v-card-title class="headline"
            >{{ eventOrder.id != null ? "Update" : "Add" }} Event
            Order</v-card-title
          >
          <v-card-text class="form_bg">
            <v-container>
              <v-row>
                <v-col cols="6" sm="6" md="6">
                  <v-text-field
                    v-model="eventOrder.sale_seq_no"
                    outlined
                    background-color="#fff"
                    label="Order ID (*)"
                    required
                    :rules="[(v) => !!v || 'Order ID is required']"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" sm="6" md="6">
                  <v-text-field
                    v-model="eventOrder.amount"
                    label="Advance Amount"
                    outlined
                    background-color="#fff"
                    rows="3"
                    :suffix="currencyCode"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <date-field
                    v-model="eventOrder.start_date"
                    label="Start Date*"
                    :rules="[(v) => !!v || 'Start date is required']"
                    :backFill="
                      checkBackfillPermission($modules.corporate.events.slug)
                    "
                  >
                  </date-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <date-field
                    v-model="eventOrder.end_date"
                    :rules="[(v) => !!v || 'End date is required']"
                    label="End Date*"
                    :backFill="
                      checkBackfillPermission($modules.corporate.events.slug)
                    "
                  >
                  </date-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="ma-2 white--text teal-color" @click="cancelEventOrder"
              >Cancel</v-btn
            >

            <v-btn
              class="ma-2 white--text blue-color"
              @click="addOrEditEventOrder"
              >{{ eventOrder.id != null ? "Update" : "Save" }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <!-- settle and booking -->

    <v-dialog v-model="bookingsDialog" scrollable width="80%">
      <v-card>
        <v-card-title class="headline">
          <v-row no-gutters>
            <v-col md="3">Sales Order Bookings</v-col>

            <v-col md="6" v-if="settlement.amount">
              Total Amount: {{ settlement.amount | toCurrency }}
            </v-col>
            <v-col md="3" class="text-right" v-if="settlement.amount > 0">
              <v-btn small color="#066a8c" dark @click="settlePaymentScreen"
                >Settle Selected</v-btn
              >
            </v-col>
            <!-- <v-col md="3" class="switch" v-if="settlement.amount > 0">
              <v-switch
                hide-details
                flat
                true-value="1"
                false-value="0"
                color="indigo darken-3"
                label="Use advance amount"
                v-model="isUseAdvanceAmount"
              ></v-switch>
            </v-col> -->
          </v-row>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-card-text>
          <v-row>
            <v-col md="12">
              <v-simple-table
                style="margin-top: 18px"
                fixed-header
                height="400px"
                class="bookings_table"
                v-if="bookings.length > 0"
              >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th v-if="creditFlag && isShowSelectAll">
                        <span>
                          Select All
                          <v-checkbox
                            style="margin-top: -8px"
                            color="#66c8c8"
                            hide-details
                            dark
                            v-model="settleAll"
                            @click="checkAll"
                          ></v-checkbox>
                        </span>
                      </th>
                      <th class="text-center">Timestamp</th>
                      <th class="text-center">Receipt ID</th>
                      <th class="text-center">Product Type</th>
                      <th class="text-center">Customer Name</th>
                      <th class="text-center">Mobile</th>
                      <th class="text-center">Payment Method</th>
                      <th class="text-center">Price</th>
                      <th class="text-center">Tax</th>
                      <th class="text-center">Total Price</th>
                      <th class="text-center" v-if="!creditFlag">Receipt</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="booking in bookings"
                      :key="booking.id"
                      class="text-center"
                    >
                      <td v-if="creditFlag && isShowSelectAll">
                        <v-checkbox
                          style="margin-top: -8px"
                          v-model="booking.isSettle"
                          color="#66c8c8"
                          :value="booking.receipt_id"
                          @change="changeAmount"
                          @click="settleAll = false"
                          hide-details
                        ></v-checkbox>
                      </td>
                      <td>{{ booking.timestamp | timeStamp }}</td>
                      <td>{{ booking.order_seq_no }}</td>
                      <td>{{ booking.product_type }}</td>
                      <td>{{ booking.first_name }} {{ booking.last_name }}</td>
                      <td>{{ booking.mobile }}</td>
                      <td>
                        {{ booking.payment_method_name }}
                      </td>
                      <td>{{ Number(booking.price) | toCurrency }}</td>
                      <td>{{ Number(booking.tax) | toCurrency }}</td>
                      <td>{{ Number(booking.total) | toCurrency }}</td>
                      <td class="md-table-cell" v-if="!creditFlag">
                        <div class="md-table-cell-container">
                          <v-btn
                            normal
                            small
                            @click="getOrderDetails(booking.order_id)"
                            >{{
                              booking.status_id == 5 ? "Pay" : "Receipt"
                            }}</v-btn
                          >
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <div class="pa-10" v-else>
                <h2 v-if="creditFlag" class="text-center">
                  No order available for settle
                </h2>
                <h2 v-else class="text-center">No Booking available</h2>
              </div>
            </v-col>
          </v-row>
          <v-pagination
            v-if="bookings.length > 0"
            v-model="pageBookings"
            :length="totalPagesOfBooking"
          ></v-pagination>
        </v-card-text>
      </v-card>
    </v-dialog>

    <confirm-model
      v-bind="confirmModel"
      @confirm="confirmActions"
      @close="(confirmModel.id = null), closeConfirmModel()"
    ></confirm-model>

    <order-details :id="orderId" @close="closeOrder"></order-details>
    <v-dialog v-model="settlePayment" scrollable width="80%">
      <v-card>
        <payment-model
          v-if="settlePayment"
          @payed="closeOrderDetails"
          @close="closeOrderDetails"
          v-bind="settlement"
        ></payment-model>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import moment from "moment";
import ConfirmModel from "@/components/Confirmation/ConfirmModel";
import OrderDetails from "@/components/Order/OrderDetails.vue";
import PaymentModel from "../../components/Order/PaymentModel.vue";

export default {
  components: {
    ConfirmModel,
    OrderDetails,
    PaymentModel,
  },
  data() {
    return {
      currentSalesId: null,
      bookingOrSettle: null,
      addEventOrderDialoge: false,
      eventOrder: {
        startmenu: false,
        endmenu: false,
      },
      eventOrderList: [],
      valid: true,
      totalPagesOfBooking: 1,
      pageBookings: 1,
      bookingsPerPage: 1,
      page: 1,
      totalPages: 1,
      perPage: null,
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
      searchParams: {
        status_id: 1,
      },
      endmenu: false,
      startmenu: false,
      datemenu: false,
      priceMenu: false,
      corporateId: null,
      settlePayment: false,
      orderBy: "created_at",
      flag: false,
      timeDuration: [
        { name: "all", title: "All" },
        { name: "week", title: "This Week" },
        { name: "year", title: "This Year" },
        { name: "month", title: "This Month" },
        { name: "custom", title: "Custom Duration" },
      ],
      menu2: false,
      menu1: false,
      bookingsDialog: false,
      bookings: [],
      creditFlag: false,
      settlement: {
        order_ids: [],

        balance: null,
      },
      orderId: null,
      settleAll: false,
      isUseAdvanceAmount: "0",
      advancePaymentDialog: false,
      payments: [],
      isShowSelectAll: false,
      orderDir: "DESC",
    };
  },
  mounted() {
    if (this.$route.params.id != null) {
      this.corporateId = this.$route.params.id;
    }
    this.loadEventOrders();
  },
  computed: {
    date1Formatted() {
      return moment(this.searchParams.start_date, "YYYY-MM-DD").format(
        "Do MMM YYYY"
      );
    },
    date2Formatted() {
      return moment(this.searchParams.end_date, "YYYY-MM-DD").format(
        "Do MMM YYYY"
      );
    },
    venueCode() {
      return this.$store.getters.venueInfo.code.toUpperCase();
    },
  },
  watch: {
    page() {
      this.loadEventOrders();
    },
    pageBookings() {
      this.getEventorderBookings(this.currentSalesId, this.bookingOrSettle);
    },
  },
  methods: {
    getOrderDetails(id) {
      this.orderId = id;
    },
    timeperiodChangeFxn() {
      this.searchParams.end_date = moment().format("YYYY-MM-DD");
      if (this.searchParams.time_intervel == "custom") {
        this.searchParams.start_date = moment()
          .subtract(30, "days")
          .format("YYYY-MM-DD");
        this.flag = true;
      } else if (this.searchParams.time_intervel == "week") {
        this.searchParams.start_date = moment()
          .startOf("week")
          .format("YYYY-MM-DD");
      } else if (this.searchParams.time_intervel == "month") {
        this.searchParams.start_date = moment()
          .startOf("month")
          .format("YYYY-MM-DD");
      } else if (this.searchParams.time_intervel == "year") {
        this.searchParams.start_date = moment()
          .startOf("year")
          .format("YYYY-MM-DD");
      } else {
        delete this.searchParams.start_date;
        delete this.searchParams.end_date;
        this.flag = false;
      }

      this.loadEventOrders();
    },

    sortColumn(type) {
      if (type == this.orderBy) {
        this.orderDir = this.orderDir == "ASC" ? "DESC" : "ASC";
      } else {
        this.orderDir = "DESC";
      }
      this.orderBy = type;
      this.loadEventOrders();
    },

    loadEventOrders() {
      let url = `?page=${this.page}`;
      if (this.searchParams.status != null) {
        url += "&status=" + this.searchParams.status;
      }
      if (this.searchParams.from_date == null) {
        this.searchParams.from_date = null;
      } else if (typeof this.searchParams.from_date != "undefined") {
        url += "&start_date=" + this.searchParams.from_date;
      }

      if (this.searchParams.name == null) {
        this.searchParams.name = null;
      } else if (typeof this.searchParams.name != "undefined") {
        url += "&order_id=" + this.searchParams.name;
      }
      if (this.searchParams.timestamp == null) {
        this.searchParams.timestamp = null;
      } else if (typeof this.searchParams.timestamp != "undefined") {
        url += "&timestamp=" + this.searchParams.timestamp;
      }
      if (this.searchParams.to_date == null) {
        this.searchParams.to_date = null;
      } else if (typeof this.searchParams.to_date != "undefined") {
        url += "&end_date=" + this.searchParams.to_date;
      }
      if (this.searchParams.status_id == null) {
        this.searchParams.status_id = null;
      } else if (typeof this.searchParams.status_id != "undefined") {
        url += "&status_id=" + this.searchParams.status_id;
      }
      if (this.searchParams.from_amount == null) {
        this.searchParams.from_amount = null;
      } else if (typeof this.searchParams.from_amount != "undefined") {
        url += "&from_amount=" + this.searchParams.from_amount;
      }
      if (this.searchParams.to_amount == null) {
        this.searchParams.to_amount = null;
      } else if (typeof this.searchParams.to_amount != "undefined") {
        url += "&to_amount=" + this.searchParams.to_amount;
      }

      url += "&order_by=" + this.orderBy + "&sort_order=" + this.orderDir;

      this.showLoader("Loading orders");
      this.$http
        .get("venues/companies/sales/" + this.corporateId + url)
        .then((response) => {
          this.hideLoader();
          if (response.status == 200 && response.data.status == true) {
            this.eventOrderList = response.data.data;
            this.totalPages = response.data.total_pages;
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    populateEditEventOrder(data) {
      this.addEventOrderDialoge = true;
      this.eventOrder = {
        amount: data.amount,
        sale_id: data.sales_id,
        end_date: data.end_date,
        start_date: data.start_date,
        sale_seq_no: data.sale_seq_no,
      };
    },
    addOrEditEventOrder(e) {
      e.preventDefault();
      if (!this.$refs.form.validate()) {
        this.showError("Please fill all required fields");
        return;
      }
      if (
        moment(this.eventOrder.start_date, "YYYY-MM-DD").isAfter(
          moment(this.eventOrder.end_date, "YYYY-MM-DD")
        )
      ) {
        this.showError("End date is less than start date");
        return;
      }
      this.eventOrder.status_id = 1;
      // if (
      //   moment().isSameOrAfter(moment(this.eventOrder.start_date, "YYYY-MM-DD"))
      // ) {
      //   this.eventOrder.status = 1;
      // }
      this.showLoader();

      var url = this.eventOrder.id
        ? `venues/companies/sales/order/${this.eventOrder.id}`
        : `venues/companies/sales/${this.corporateId}`;
      this.$http
        .post(url, this.eventOrder)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            let message =
              this.eventOrder.id != null
                ? "Corporate details updated successfully"
                : "Corporate added successfully";
            this.showSuccess(message);
            this.hideLoader();
            this.loadEventOrders();
            this.addEventOrderDialoge = false;
            this.eventOrder = {};
            this.$refs.form.resetValidation();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },

    changeEventOrderStatus(id) {
      this.showLoader("Delete.....");
      this.$http
        .put("venues/companies/sales/" + id + "/status")
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.hideLoader();
            this.loadEventOrders();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    addEventOrder() {
      this.eventOrder = { corporate_id: this.corporateId };
      this.addEventOrderDialoge = true;
    },
    cancelEventOrder() {
      this.$refs.form.resetValidation();
      this.addEventOrderDialoge = false;
    },
    deleteEventOrder(id) {
      this.confirmModel = {
        id: id,
        title: `Do you want to delete this eventOrder?`,
        description:
          "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
        type: "delete",
      };
    },
    startDate() {
      if (this.eventOrder.start_date == null) return "";
      return moment(this.eventOrder.start_date).format("Do MMMM YYYY");
    },
    endDate() {
      if (this.eventOrder.end_date == null) return "";
      return moment(this.eventOrder.end_date).format("Do MMMM YYYY");
    },
    getEventorderBookings(data, type) {
      this.settleAll = false;
      this.currentSalesId = data;
      this.bookingOrSettle = type;
      let url = `?page=${this.pageBookings}`;
      this.bookings = [];
      this.showLoader();
      if (type == "settle") {
        url += "&credit";
        this.$http
          .get("venues/companies/sales/order/history/" + data.sales_id + url)
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              this.settlement.advance_payment_id = data.advance_payment_id;
              this.totalPagesOfBooking = response.data.total_pages;
              this.bookings = response.data.data;
              this.isShowSelectAll = true;
              this.bookingsDialog = true;
              this.creditFlag = true;
              if (data.current_balance > 0) {
                this.settlement.advance_amount = data.current_balance;
              }
            }
            this.hideLoader();
          })
          .catch((error) => {
            this.errorChecker(error);
          });
      } else {
        url += "&status_id=4,5,7";
        this.$http
          .get("venues/companies/sales/order/history/" + data.sales_id + url)
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              this.totalPagesOfBooking = response.data.total_pages;
              this.bookings = response.data.data;
              this.settlement = {};
              this.bookingsDialog = true;
              this.creditFlag = false;
            }
            this.hideLoader();
          })
          .catch((error) => {
            this.errorChecker(error);
          });
      }
    },
    checkAll() {
      this.bookings.forEach((element) => {
        element.isSettle = this.settleAll;
      });
      this.changeAmount();
      this.$forceUpdate();
    },
    changeAmount() {
      this.settlement.amount = this.bookings.reduce((a, b) => {
        if (b.isSettle == true) {
          return a + parseFloat(b.total);
        } else {
          return a + 0;
        }
      }, 0);
      this.$forceUpdate();
    },

    settlePaymentScreen() {
      this.settlement.order_ids = [];
      this.bookings.forEach((x) => {
        if (x.isSettle == true) {
          this.settlement.order_ids.push(x.order_id);
        }
      });

      if (this.settlement.advance_amount != undefined) {
        this.confirmModel = {
          id: 1,
          title: `Do you want to use advance amount?`,
          description: `<h1>Advance Amount :  <b>${this.settlement.advance_amount}</b></h1>. <br/> Do you need to continue your action ?`,
          type: "advance_amount",
        };
      } else {
        this.closeConfirmModel();
      }
      this.$store.commit("setOrderDetails", []);
      this.$store.commit("setOrderItems", []);
    },

    confirmActions(data) {
      this.payments = [];
      if (data.type == "delete") {
        this.changeEventOrderStatus(data.id);
      }
      if (data.type == "advance_amount") {
        this.bookingsDialog = false;
        this.settlePayment = true;
        if (this.settlement.advance_amount > this.settlement.amount) {
          this.payments.unshift({
            card_type_id: null,
            payment_code: null,
            payment_method_id: 1,
            amount: this.settlement.amount,
            payment_method: null,
          });
        } else {
          this.payments.unshift(
            {
              card_type_id: null,
              payment_code: null,
              payment_method_id: 1,
              amount: this.settlement.advance_amount,
              payment_method: null,
            },
            {
              card_type_id: null,
              payment_code: null,
              payment_method_id: null,
              amount: null,
              payment_method: null,
            }
          );
        }

        this.$store.commit("setOrderPayments", this.payments);
      }
      this.$store.commit("setOrderDetails", []);
      this.$store.commit("setOrderItems", []);
      this.confirmModel.id = null;
    },

    closeConfirmModel() {
      if (this.settlement.order_ids.length) {
        this.bookingsDialog = false;
        this.settlePayment = true;
        delete this.settlement.advance_payment_id;
        this.payments = [
          {
            card_type_id: null,
            payment_code: null,
            payment_method_id: null,
            amount: null,
            payment_method: null,
          },
        ];

        this.$store.commit("setOrderPayments", this.payments);
      }
    },

    goToCompany() {
      this.$router.push({ name: "CorporateDashboard" }, () => {});
    },
    closeOrderDetails() {
      this.bookingsDialog = false;
      this.settlePayment = false;
      this.settleAll = false;
      this.isShowSelectAll = false;
      this.settlement = {};
      this.payments = [];
      this.$store.commit("setOrderPayments", []);
      this.loadEventOrders();
    },
    closeOrder() {
      this.orderId = null;
      this.getEventorderBookings(this.currentSalesId, "booking");
    },
  },
};
</script>

<style scoped>
.bookings_table table thead tr th {
  background: #066a8c !important;
  color: #fff !important;
}
.switch .v-input {
  padding: 3px 0px 0px 21px;
  margin: 0;
}
</style>
