<template>
  <v-card tile>
    <v-card-text>
      <v-row class="pt-6">
        <v-col md="8">
          <v-card outlined>
            <v-card-title
              ><span class="text_line">SELECT PAYMENT METHOD OKK</span>
              <v-spacer></v-spacer>
              <v-btn outlined class="mr-4"
                ><v-icon small>mdi-wallet</v-icon> Cash Wallet:
                {{ wallet.cash | toCurrency }}</v-btn
              >
              <v-btn outlined @click="showWalletProducts"
                ><v-icon small>mdi-wallet-travel</v-icon> Products Wallet:
                {{ wallet.products }}</v-btn
              >
              <v-row class="mt-5" style="width: 100%">
                <div class="price_div">
                  Total {{ parseFloat(paymentTotal) | toCurrency }} /
                  {{
                    discountPercentage && discountAmount
                      ? discountAmount
                      : amount
                      ? amount
                      : orderDetails.credit_amount
                      ? orderDetails.credit_amount
                      : orderDetails.total | toCurrency
                  }}
                </div>
              </v-row>
            </v-card-title>
            <v-card-text>
              <v-form ref="form">
                <v-row>
                  <v-col cols="12" md="12">
                    <template v-for="(method, index) in payments">
                      <payment-method
                        v-bind="method"
                        :index="index"
                        :key="`p_${index}`"
                        @remove="removePaymentMethod"
                        @refresh="refreshFormValidation"
                        @validate="validate"
                        :advanceAmount="
                          discountPercentage && discountAmount
                            ? parseFloat(discountAmount)
                            : parseFloat(amount)
                        "
                      ></payment-method>
                    </template>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions
              v-if="!payments.find((x) => x.payment_method == 'Complementary') && isEnabledMultiPayment()"
            >
              <v-spacer></v-spacer>
              <v-btn outlined color="primary" @click="addNewPaymentMethod">
                Add New Method <v-icon small right>mdi-plus</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
          <!-- <v-row>
            <v-col style="display: flex; margin-top: 15px">
              <v-spacer></v-spacer>

              <v-btn outlined center
                >Total {{ parseFloat(paymentTotal) | toCurrency }} /
                {{
                  discountPercentage && discountAmount
                    ? discountAmount
                    : amount
                    ? amount
                    : orderDetails.credit_amount
                    ? orderDetails.credit_amount
                    : orderDetails.total | toCurrency
                }}</v-btn
              >
              <v-spacer></v-spacer>
            </v-col>
          </v-row> -->

          <v-btn dark class="yellow-color" absolute bottom @click="payLater()"
            >Pay later</v-btn
          >
          <v-btn
            v-if="!order_ids"
            dark
            class="red"
            outlined
            absolute
            bottom
            @click="cancel()"
            style="margin-left: 100px"
            >Cancel Reservation</v-btn
          >
        </v-col>
        <v-col md="4">
          <v-card outlined>
            <v-card-title
              ><span class="text_line">ORDER SUMMARY</span></v-card-title
            >
            <v-card-text>
              <v-list
                three-line
                style="min-height: 150px; max-height: 250px"
                class="overflow-y-auto"
              >
                <v-list-item
                  v-for="(product, i) in products"
                  :key="i"
                  ripple
                  @click="() => {}"
                >
                  <v-list-item-avatar title height="64" width="64">
                    <view-image :image="product.image"></view-image>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title class="text-body">{{
                      product.name
                    }}</v-list-item-title>
                    <v-list-item-subtitle>
                      <div class="d-flex justify-space-between text-caption">
                        <div class="font-weight-bold">
                          {{
                            product.product_type_id == 4
                              ? product.price
                              : product.product_price
                          }}
                          x {{ product.quantity }}
                        </div>
                        <div class="font-weight-bold">
                          Tax
                          {{
                            (product.discount
                              ? product.actual_tax
                              : product.tax) | toCurrency
                          }}
                        </div>
                        <div class="font-weight-bold">
                          {{ currencyCode }}
                          {{
                            product.discount
                              ? product.actual_total
                              : product.total
                          }}
                        </div>
                      </div>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-divider></v-divider>
              <div class="d-flex justify-space-between subtitle-2">
                <div class="">Subtotal</div>
                <div class="">
                  {{
                    (orderDetails.discount
                      ? orderDetails.actual_price
                      : orderDetails.price) | toCurrency
                  }}
                </div>
              </div>
              <div class="d-flex justify-space-between subtitle-2">
                <div class="">Tax</div>
                <div class="">
                  {{
                    (orderDetails.discount
                      ? orderDetails.actual_tax
                      : orderDetails.tax) | toCurrency
                  }}
                </div>
              </div>
              <div
                v-if="orderDetails.credit_amount"
                class="d-flex justify-space-between subtitle-2"
              >
                <div class="">Paid Amount</div>
                <div class="">
                  -{{
                    (orderDetails.credit_amount
                      ? orderDetails.total - orderDetails.credit_amount
                      : 0) | toCurrency
                  }}
                </div>
              </div>
              <div
                class="d-flex justify-space-between subtitle-2 green--text"
                v-if="
                  orderDetails.discount &&
                    orderDetails.actual_price - orderDetails.price != 0
                "
              >
                <div class="">Discount - {{ orderDetails.promotion_name }}</div>
                <div class="">
                  {{
                    (orderDetails.actual_price - orderDetails.price)
                      | toCurrency
                  }}
                  +
                  {{
                    (orderDetails.actual_tax - orderDetails.tax) | toCurrency
                  }}
                  =
                  {{
                    (orderDetails.actual_total - orderDetails.total)
                      | toCurrency
                  }}
                </div>
              </div>
              <v-divider></v-divider>
              <div
                class="d-flex justify-space-between font-weight-bold subtitle-1 black--text"
              >
                <div>Total</div>

                <div v-if="!order_ids">
                  <span
                    class="text-decoration-line-through pt-1"
                    v-if="
                      orderDetails.discount &&
                        orderDetails.actual_price - orderDetails.price != 0
                    "
                  >
                    {{ orderDetails.actual_total | toCurrency }}
                  </span>
                  {{
                    amount
                      ? amount >= advance_amount
                        ? amount - advance_amount
                        : amount
                      : orderDetails.credit_amount
                      ? orderDetails.credit_amount
                      : orderDetails.total | toCurrency
                  }}
                </div>
                <div v-else>
                  <span
                    class="text-decoration-line-through pt-1"
                    v-if="discountAmount != amount && discountAmount != null"
                  >
                    {{ amount | toCurrency }}
                  </span>
                  {{
                    discountAmount != amount && discountAmount != null
                      ? discountAmount
                      : amount | toCurrency
                  }}
                  <!-- {{
                    discountPercentage && discountAmount
                      ? amount - discountAmount
                      : discountAmount
                      ? amount - discountAmount
                      : amount | toCurrency
                  }} -->
                </div>
              </div>
            </v-card-text>
            <v-divider></v-divider>
            <div class="pa-2">
              <v-row
                v-if="
                  !orderDetails.promotion_code &&
                    !this.is_wallet_redeem &&
                    !payments.find((x) => x.payment_method == 'Complementary')
                "
              >
                <v-col md="6">
                  <v-text-field
                    v-model="discountAmount"
                    label="Special discount"
                    outlined
                    :prefix="currencyCode"
                    :rules="[
                      (v) => {
                        if (v) {
                          if (isNaN(v)) {
                            return 'Amount must be a number';
                          }
                        }
                        return true;
                      },
                    ]"
                    @keyup="specialDiscountChange('amount')"
                  ></v-text-field>
                </v-col>
                <v-col md="6">
                  <v-text-field
                    v-model="discountPercentage"
                    suffix="%"
                    :rules="[
                      (v) => {
                        if (v) {
                          if (isNaN(v)) {
                            return 'Amount must be a number';
                          }
                          if (v > 100) {
                            return 'Percentage should be less than 100';
                          }
                        }
                        return true;
                      },
                    ]"
                    label="Percentage"
                    outlined
                    @keyup="specialDiscountChange('percentage')"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-textarea
                v-model="paymentNote"
                rows="3"
                label="Payment note"
                outlined
              ></v-textarea>
            </div>

            <v-divider></v-divider>
            <v-card-actions>
              <v-btn block large color="rgb(0, 176, 175)" dark @click="payOrder"
                >Pay now</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <confirm-model
      v-bind="confirmModel"
      @confirm="confirmActions"
      @close="confirmModel.id = null"
    ></confirm-model>
    <customer-product-wallet
      v-bind="walletModel"
      @refreshWallet="refreshWallet"
      @close="
        (walletModel.products = []),
          (walletModel.showModal = false),
          (walletModel.order_id = null)
      "
    ></customer-product-wallet>
  </v-card>
</template>

<script>
import PaymentMethod from "./PaymentMethod.vue";
import CustomerProductWallet from "./CustomerProductWallet.vue";
export default {
  components: {
    "payment-method": PaymentMethod,
    "customer-product-wallet": CustomerProductWallet,
  },
  props: {
    "order-id": { type: Number, default: null },
    order_ids: { type: Array, default: null },
    advance_payment_id: { type: Number, default: null },
    advance_amount: { type: Number, default: null },
    amount: { type: Number, default: null },
    wallet: { type: Object, default: () => {} },
  },
  data() {
    return {
      balance: 0,
      confirmModel: {},
      paymentNote: null,
      discountAmount: null,
      prevdiscountAmount: null,
      discountPercentage: null,
      walletModel: {
        showModal: false,
        products: [],
        order_id: null,
      },
      isEditing: true,
      is_wallet_redeem: false,
    };
  },

  watch: {
    orderDetails(val) {
      if (val.wallet_redemption && val.wallet_redemption.length > 0) {
        this.is_wallet_redeem = true;
      } else {
        this.is_wallet_redeem = false;
      }
    },
  },
  mounted() {
    if (!this.$store.getters.getSalesConfig) {
      this.$store.dispatch("loadVenueSalesConfig");
    }
    if (
      this.orderDetails &&
      this.orderDetails.wallet_redemption &&
      this.orderDetails.wallet_redemption.length > 0
    ) {
      console.log(this.is_wallet_redeem);
      this.is_wallet_redeem = true;
    } else {
      this.is_wallet_redeem = false;
    }
    if (this.orderDetails && this.orderDetails.order_notes) {
      this.paymentNote = this.orderDetails.order_notes;
    }

    window.addEventListener("beforeunload", this.stopRefresh);
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.stopRefresh);
  },
  computed: {
    salesConfig() {
      return this.$store.getters.getSalesConfig;
    },
    products() {
      return this.$store.getters.getOrderItems;
    },
    payments() {
      return this.$store.getters.getOrderPayments;
    },
    orderDetails() {
      return this.$store.getters.getOrderDetails;
    },
    paymentTotal() {
      return this.$store.getters.getOrderPaymentTotal;
    },
  },
  methods: {
    stopRefresh(event) {
      if (!this.isEditing) return;
      event.preventDefault();
      event.returnValue = "";
      this.cancelRedeem();
    },
    refreshWallet() {
      this.$emit("refreshWallet");
      if (
        this.orderDetails.wallet_redemption &&
        this.orderDetails.wallet_redemption.length > 0
      ) {
        this.is_wallet_redeem = true;
      } else {
        this.is_wallet_redeem = false;
      }
    },
    showWalletProducts() {
      this.showLoader("Loading...");

      let data = null;
      if (this.order_ids) {
        data = {
          order_id: this.order_ids,
        };
      } else {
        data = {
          order_id: this.orderDetails.id,
        };
      }
      this.$http
        .post(`venues/customers/products-wallet/get-customer-order-all`, data)
        .then((response) => {
          if (response.status == 200 && response.data.status) {
            this.hideLoader();
            let data = response.data.data;
            this.walletModel.products = [];
            data.forEach((d) => {
              this.walletModel.products.push({
                id: d.id,
                name: d.product.name,
                quantity: d.quantity,
                type: d.product.product_type,
                status: d.status_id,
              });
            });
          }
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
      this.walletModel.order_id = this.order_ids
        ? this.order_ids
        : this.orderDetails.id;
      this.walletModel.showModal = true;
    },
    addNewPaymentMethod() {
      let currentOrderTotal =
        this.discountPercentage && this.discountAmount
          ? this.discountAmount
          : this.amount
          ? this.amount
          : this.orderDetails.credit_amount
          ? this.orderDetails.credit_amount
          : this.orderDetails.total;
      if (parseFloat(currentOrderTotal) < parseFloat(this.paymentTotal)) {
        this.showError("Total payment amount is greater than order amount");
        return;
      }
      this.$store
        .dispatch("addNewPaymentMethod")
        .then(() => {})
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    removePaymentMethod(data) {
      this.confirmModel = {
        id: data.index,
        title: "Do you want to remove this Payment Method?",
        description:
          "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
        type: "remove_payment_method",
        data: { id: data.id, index: data.index },
      };
    },
    refreshFormValidation() {
      this.$refs.form.resetValidation();
    },
    validate() {
      this.$refs.form.$el.removeAttribute("novalidate");
      this.$refs.form.validate();
      this.$refs.form.$el.setAttribute("validate", "validate");
    },
    cancel() {
      this.confirmModel = {
        id: this.orderDetails.id,
        title: "Do you want to cancel this order?",
        description:
          "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
        type: "cancel",
      };
    },
    confirmActions(data) {
      if (data.type == "remove_payment_method") {
        setTimeout(() => {
          this.$store.commit("removePaymentMethod", data.data.index);
        });
      } else if (data.type == "cancel") {
        this.$emit("cancel");
      }
      this.confirmModel.id = null;
    },
    specialDiscountChange(type) {
      this.$store
        .dispatch("resetPaymentMethod")
        .then(() => {
          this.$refs.form.resetValidation();
        })
        .catch((error) => {
          this.errorChecker(error);
        });

      this.$forceUpdate();

      if (type == "percentage") {
        this.discountAmount = null;
        if (this.order_ids && this.order_ids.length > 0) {
          this.discountAmountForCompanySettle("percentage");
        } else {
          if (this.discountPercentage <= 100) {
            this.$store.commit(
              "addSpecialDiscountPercentage",
              this.discountPercentage
            );
          }
        }
      } else if (type == "amount") {
        this.discountPercentage = null;
        if (this.order_ids && this.order_ids.length > 0) {
          this.discountAmountForCompanySettle("amount");
        } else {
          //Regex to limit input to 2 decimal places
          let stringValue = this.discountAmount.toString();
          let regex = /^[0-9]*(\.[0-9]{0,2})?$/;
          if (!stringValue.match(regex)) {
            this.discountAmount = parseFloat(this.prevdiscountAmount);
          }
          this.prevdiscountAmount = parseFloat(this.discountAmount);

          if (
            this.discountAmount <
            this.$store.getters.getOrderBeforeDiscount.total
          ) {
            this.$store.commit("addSpecialDiscount", this.discountAmount);
          } else {
            this.showError("Payment amount not matching with order amount!");
            this.discountAmount = null;
            this.$store.commit("addSpecialDiscount", 0);
          }
        }
      }
      if (
        this.orderDetails.wallet_redemption &&
        this.orderDetails.wallet_redemption.length > 0
      ) {
        this.is_wallet_redeem = true;
      } else {
        this.is_wallet_redeem = false;
      }
      //console.log(this.orderDetails);
    },

    discountAmountForCompanySettle(type) {
      if (type == "percentage") {
        if (isNaN(this.discountPercentage)) {
          return;
        }
        if (this.discountPercentage < 0) {
          this.discountPercentage = 0;
        }
        if (this.discountPercentage >= 100) {
          this.discountPercentage = 100;
          this.discountAmount = 0;
        } else {
          this.discountAmount =
            this.amount -
            ((this.amount / 100) * this.discountPercentage).toFixed(2);
        }
      } else {
        if (this.discountAmount == "" || this.discountAmount == null) {
          this.discountAmount = null;
          this.discountPercentage = null;
          return;
        }

        if (isNaN(this.discountAmount)) {
          this.discountAmount = this.amount;
          this.discountPercentage = 100;
        }
        if (this.discountAmount == 0) {
          this.discountPercentage = 100;
        } else if (this.discountAmount == this.amount) {
          this.discountPercentage = 0;
        } else {
          this.discountPercentage = (
            (this.discountAmount / this.amount) *
            100
          ).toFixed(2);
        }
      }
    },

    payOrder() {
      this.showLoader("Payment processing....");
      let data = {};
      if (this.order_ids) {
        data = {
          order_id: this.order_ids,
          advance_payment_id: this.advance_payment_id
            ? this.advance_payment_id
            : null,
          payments: this.payments,
        };
      } else {
        data = {
          order_id: this.orderDetails.id,
          payments: this.payments,
        };

        if (this.payments.find((x) => x.payment_method == "Complementary")) {
          data.isComplementary = true;
        }
      }
      if (this.paymentNote) {
        data.notes = this.paymentNote;
      }

      if (this.discountAmount) {
        data.discount = parseFloat(this.discountAmount);
      }
      if (this.discountPercentage && !this.order_ids) {
        data.discount = this.orderDetails.total;
      }
      if (!this.$refs.form.validate()) {
        this.hideLoader();
        this.showError("Please fill all fields");
        return;
      }
      /** Check if duplicate card with same details exist restrict them */
      if (this.payments.length) {
        let duplicatesCard = [];
        this.payments.forEach((el, i) => {
          if (!el.payment_method == "Card") return null;
          this.payments.forEach((element, index) => {
            if (i === index) return null;
            if (
              element.card_type_id === el.card_type_id &&
              element.payment_code === el.payment_code &&
              element.payment_method === el.payment_method &&
              element.card_type_id != null &&
              element.payment_code != null
            ) {
              if (!duplicatesCard.includes(el)) duplicatesCard.push(el);
            }
          });
        });
        if (duplicatesCard.length) {
          this.hideLoader();
          this.showError("Duplicate cards not allowed");
          return;
        }
      }
      this.$http
        .post("venues/orders", data)
        .then((response) => {
          this.hideLoader();
          if (response.status == 200) {
            this.showSuccess("Payment success");
            if (this.orderDetails.id) {
              this.$store.dispatch("loadOrderDetails", this.orderDetails.id);
            }
            this.$emit("payed");
          }
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
    },

    payLater() {
      if (this.paymentNote) {
        let data = {
          order_id: this.orderDetails.id,
          notes: this.paymentNote,
        };
        this.showLoader();
        this.$http
          .post("venues/orders/pay-later", data)
          .then((response) => {
            this.hideLoader();
            if (response.status == 200) {
              this.showSuccess("Payment note updated!");
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
      }
      this.cancelRedeem();
      this.$emit("close");
    },
    cancelRedeem() {
      let cancel_order = 0;
      if (this.order_ids) {
        cancel_order = this.order_ids;
      } else {
        cancel_order = this.orderDetails.id;
      }
      this.$http
        .get(`venues/customers/products-wallet/cancel-redeem/${cancel_order}`)
        .then(() => {
          return;
        })
        .catch((error) => {
          this.errorChecker(error);
        });
      this.isEditing = true;
    },
    isEnabledMultiPayment() {
      if (this.salesConfig) {
        if (this.salesConfig.enable_multi_payment == 1) {
          return true;
        } else {
          return false;
        }
      }
      return true;
    }
  },
};
</script>

<style scoped>
.text_line {
  border-bottom: 3px solid rgb(0, 176, 175);
}
.price_div {
  width: 100%;
  background: #f2f2f2;
  padding-top: 3px;
  padding-bottom: 3px;
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 5px;
  border: 0.5px solid black;
}
</style>
